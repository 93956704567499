/* eslint no-unused-vars: 0 */

import { navigate } from "gatsby";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import PropTypes from "prop-types";
import React from "react";

const FormItem = Form.Item;
const { TextArea } = Input;
import "antd/lib/form/style/index.css";
import "antd/lib/input/style/index.css";
import "antd/lib/button/style/index.css";
import { ThemeContext } from "../../layouts";

class ContactForm extends React.Component {
  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.sendMessage(values);
      }
    });
  };

  sendMessage = values => {
    fetch("https://api.nickthecloudguy.com/v1/contact", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      body: JSON.stringify(values),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
      .then(() => {
        console.log("Form submission success");
        navigate("/contactsuccess");
      })
      .catch(error => {
        console.error("Form submission error:", error);
        this.handleNetworkError();
      });
  };

  handleNetworkError = e => {
    console.log("submit Error");
    navigate("/contacterror");
  };

  render() {
    const { theme } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <React.Fragment>
        <section className="form">
          <form name="contact" onSubmit={this.handleSubmit.bind(this)} method="post">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <FormItem label="Name">
              {getFieldDecorator("name", {
                rules: [
                  {
                    whitespace: true
                  }
                ]
              })(<TextArea name="name" autosize={{ minRows: 1, maxRows: 1 }} />)}
            </FormItem>
            <FormItem label="E-mail">
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "Please input your e-mail address!",
                    whitespace: true,
                    type: "email"
                  }
                ]
              })(<TextArea name="email" autosize={{ minRows: 1, maxRows: 1 }} />)}
            </FormItem>
            <FormItem label="Message">
              {getFieldDecorator("message", {
                rules: [
                  {
                    required: true,
                    message: "Write your message!",
                    whitespace: true
                  }
                ]
              })(<TextArea name="message" placeholder="" autosize={{ minRows: 4, maxRows: 10 }} />)}
            </FormItem>
            <FormItem>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </FormItem>
          </form>
        </section>
        {/* --- STYLES --- */}
        <style jsx>{`
          .form {
            background: transparent;
          }
          .form :global(.ant-row.ant-form-item) {
            margin: 0 0 1em;
          }
          .form :global(.ant-row.ant-form-item:last-child) {
            margin-top: 1em;
          }
          .form :global(.ant-form-item-control) {
            line-height: 1em;
          }
          .form :global(.ant-form-item-label) {
            line-height: 1.5em;
            margin-bottom: 0.5em;
          }
          .form :global(.ant-form-item) {
            margin: 0;
          }
          .form :global(.ant-input) {
            appearance: none;
            height: auto;
            font-size: 1.2em;
            padding: 0.5em 0.6em;
          }
          .form :global(.ant-btn-primary) {
            height: auto;
            font-size: 1.2em;
            padding: 0.5em 3em;
            background: ${theme.color.brand.primary};
            border: 1px solid ${theme.color.brand.primary};
          }
          .form :global(.ant-form-explain) {
            margin-top: 0.2em;
          }

          @from-width desktop {
            .form :global(input) {
              max-width: 50%;
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}

ContactForm.propTypes = {
  form: PropTypes.object,
  theme: PropTypes.object.isRequired
};

const Contact = Form.create({})(ContactForm);

export default Contact;
